import { Link } from "gatsby";
import React from "react";
import Section from "../components/Section";

function Page404() {
  return (
    <main>
      <Section align="center">
        <h1>Page not found</h1>
        <Link to="/">
          <button>Return to Home</button>
        </Link>
      </Section>
    </main>
  );
}

export default Page404;

export function Head() {
  const noTrack = process.env.GATSBY_STAGING ? (
    <meta name="robots" content="noindex, nofollow" />
  ) : (
    <meta />
  );
  return <>{noTrack}</>;
}
